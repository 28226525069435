<template>
  <el-row class="account-label-filter" v-if="!collapseFilter">
    <div class="filter-label">常用标签</div>
    <div ref="labelsRef" :class="'labels ' + (showAll ? 'all' : 'part')">
      <div :class="'label-item ' + (selected.indexOf(label.id) >= 0 ? 'selected' : '')"
           v-for="label in labels"
           :key="label.id" @click="switchClick(label)">{{ label.name }}
      </div>
    </div>
    <div v-if="showAllHeight >= 38" class="show-all-btn">
      <el-button type="text" @click="showAll=!showAll">
        {{ showAll ? '收起 ' : '更多 ' }}<i v-if="showAll" class="el-icon-arrow-up"></i>
        <i v-else class="el-icon-arrow-down"></i>
      </el-button>
    </div>
  </el-row>
  <el-row class="account-label-filter mb-10" v-if="!collapseFilter">
    <div class="filter-label">更多标签</div>
    <el-select multiple filterable clearable collapse-tags size="small" placeholder="筛选" @change="handleChange"
               v-model="selected">
      <el-option :label="label.name" :value="label.id" v-for="label in labels" :key="label.id"></el-option>
    </el-select>
  </el-row>

  <el-row class="account-label-filter mb-10">
    <div class="filter-label" style="height: 26px">筛选条件</div>
    <div class="labels">
      <template v-for="label in labels" :key="label.id">
        <el-tag class="selected-tag" size="mini" v-if="selected.indexOf(label.id) > -1">
          {{ label.name }}
        </el-tag>
      </template>
      <el-button type="text" class="account-label-filter-clear" @click="resetSelected">
        <i class="icon iconfont icon-qingchu"></i> 清除筛选
      </el-button>
      <el-button type="text" class="collapse-filter" @click="collapseFilter=!collapseFilter">
        {{ collapseFilter ? '展开筛选' : '收起筛选' }}
        <i v-if="collapseFilter" class="el-icon-arrow-down"></i>
        <i v-else class="el-icon-arrow-up"></i>
      </el-button>
    </div>
  </el-row>

</template>

<script setup>
import {ref, onMounted, nextTick, defineEmits} from "vue";
import api from '@/request/api/account'
import {ElMessage} from "element-plus";

let emit = defineEmits(['selected-labels'])

let labels = ref([{id: 0, name: '全部'}])

let selected = ref([0])

let labelsRef = ref()
let showAllHeight = ref(0)
let showAll = ref(false)

let beforeSelected = ref([0])
let collapseFilter = ref(false)

let resetSelected = () => {
  selected.value = [0]
  beforeSelected.value = [0]
  emit('selected-labels', selected.value)
}

let handleChange = (val) => {
  let addItem = selected.value.filter(item => {
    return beforeSelected.value.indexOf(item) === -1
  })

  let delItem = beforeSelected.value.filter(item => {
    return selected.value.indexOf(item) === -1
  })

  // 当前选中全部标签 || 当前删除全部标签 || 当前未选中任何标签
  if ((addItem.length > 0 && addItem.indexOf(0) > -1) || (delItem.length > 0 && delItem.indexOf(0) > -1) || selected.value.length === 0) {
    return resetSelected()
  }

  // 新增选择标签，并且目前已选列表存在全部标签
  if (addItem.length > 0 && selected.value.indexOf(0) > -1) {
    selected.value.splice(selected.value.indexOf(0), 1)
    beforeSelected.value = [].concat(selected.value)
    emit('selected-labels', selected.value)
    return
  }

  //选中标签超过5个
  if (selected.value.length > 5) {
    if (addItem.length > 0 && selected.value.indexOf(addItem[0]) > -1) {
      selected.value.splice(selected.value.indexOf(addItem[0]), 1)
    } else {
      selected.value.splice(-1, 1)
    }

    beforeSelected.value = [].concat(selected.value)
    ElMessage.closeAll()
    return ElMessage.warning({message: '最多只能选择5项', showClose: true})
  }

  emit('selected-labels', selected.value)
  beforeSelected.value = val === undefined ? [].concat(selected.value) : val
}

let switchClick = item => {
  if (selected.value.indexOf(item.id) >= 0) {
    selected.value.splice(selected.value.indexOf(item.id), 1)
  } else {
    selected.value.push(item.id)
  }
  handleChange()
}


onMounted(async () => {
  let res = await api.facebook.getAccountLabels()
  if (res.data.label) {
    labels.value = [{id: 0, name: '全部'}].concat(res.data.label)
  } else {
    labels.value = [{id: 0, name: '全部'}]
  }

  nextTick(() => {
    showAllHeight.value = labelsRef.value.offsetHeight
  })


})

</script>

<style lang="less" scoped>
.account-label-filter {
  margin-bottom: 16px;

  :deep(.filter-label) {
    font-family: PingFangSC, PingFang SC;
    font-size: 14px;
    line-height: 22px;
    color: #8C8C8C;
    width: 56px;
    display: inline-flex;
    justify-content: flex-end;
    margin-right: 24px;
    align-items: center;
    height: 32px;
  }

  :deep(.labels) {
    flex: 1;
  }

  :deep(.labels.part) {
    height: 40px;
    overflow: hidden;
  }

  :deep(.label-item) {
    display: inline-block;
    font-family: PingFangSC, PingFang SC;
    font-size: 14px;
    line-height: 22px;
    padding: 5px 8px;
    color: #595959;
    cursor: pointer;
    margin-bottom: 16px;
    margin-right: 16px;
  }

  :deep(.label-item.selected) {
    border: 1px solid #2B82FF;
    border-radius: 4px;
    color: #2B82FF !important;
  }

  :deep(.show-all-btn) {
    .el-button {
      color: #595959;
      font-size: 14px;
      padding: 5px 0;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      line-height: 22px;
      min-height: 0;
    }

    .el-button:hover {
      color: #2B82FF;
    }
  }

  :deep(.selected-tag) {
    padding: 2px 8px;
    font-size: 14px;
    line-height: 20px;
    height: 26px;
    color: #2B82FF;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    text-align: center;
    font-style: normal;
    text-transform: none;
    background-color: rgba(43, 130, 255, 0.10);
    margin: 0 10px 10px 0;
    align-self: center;
  }

  :deep(.account-label-filter-clear) {
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #2B82FF;
    min-height: 26px;
    line-height: 20px;
    text-align: center;
    font-style: normal;
    text-transform: none;
    align-self: center;
    padding: 0;
    margin-right: 10px;
  }

  :deep(.collapse-filter) {
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #595959;
    min-height: 26px;
    line-height: 20px;
    text-align: center;
    font-style: normal;
    text-transform: none;
    align-self: center;
    padding: 0;
  }
}
</style>