import axios from '@/request/http'
import base from '@/request/base'

// 获取FB昨日数据
export const getFBYesterdayData = (params) => {
  return axios.get(`${base.admin_url}/cloud/account_data/fb_dashboard_one`, {params: params})
}

// 获取FB日数据
export const getFBDailyData = (params) => {
  return axios.get(`${base.admin_url}/cloud/account_data/fb_dashboard_two`, {params: params})
}

// 获取FB成效分析数据
export const getFBRoasData = (params) => {
  return axios.get(`${base.admin_url}/cloud/account_data/fb_dashboard_three`, {params: params})
}

// 获取FB投放地区数据
export const getFBCountryData = (params) => {
  return axios.get(`${base.admin_url}/cloud/account_data/fb_dashboard_four`, {params: params})
}

