<template>
  <el-card shadow="never" class="box-card label-card">
    <el-row class="SubHead" style="font-size: 14px; margin-bottom: 16px;">查看维度</el-row>
    <account-label-filter @selected-labels="onLabelSelectedChange"></account-label-filter>
  </el-card>
  <el-card class="box-card" id="firstRow" v-loading="yesterdayLoading">
    <template #header>
      <div class="card-header">
        <span class="SubHead">昨日数据</span>
        <!-- <router-link to="/reportList">
          <span class="brandColor reportDetail">查看详情</span>
        </router-link> -->
      </div>
    </template>
    <el-row :gutter="10" style="width: 100%;flex-wrap:nowrap;">
      <!-- 昨日数据表1 -->
      <el-col :span="6" style="min-width:290px;">
        <el-card shadow="never" class="neverCard">
          <div class="never-header">
            <span class="Text">昨日总消耗量</span>
            <el-tooltip class="item" effect="light" content="" placement="top">
              <template #content>
                昨日总消耗量：<br/>
                昨日全部广告账户广告消耗量的综合<br/>
                日均消耗量：<br/>
                广告账户一周内消耗量平均值
              </template>
              <i class="el-icon-warning-outline"></i>
            </el-tooltip>
          </div>
          <p class="MainHead never-p">$ {{ $filters.thousands(yesterdayData.yesterday_spent) }}</p>
          <el-row class="yesterday-data-content">
            <el-col :span="12">
              <p class="never-p">
                <span class="Text">周同比</span>
                <i class="el-icon-caret-top" v-if="yesterdayData.week_spent_type == 1"></i>
                <i class="el-icon-caret-bottom" v-else-if="yesterdayData.week_spent_type == 2"></i>
                <i v-else></i>
                {{ yesterdayData.week_on_week_spent }} <i v-if="yesterdayData.week_spent_type != 0">%</i>
              </p>
            </el-col>
            <el-col :span="12">
              <p class="never-p">
                <span class="Text">日环比</span>
                <i class="el-icon-caret-top" v-if="yesterdayData.day_spent_type == 1"></i>
                <i class="el-icon-caret-bottom" v-else-if="yesterdayData.day_spent_type == 2"></i>
                <i v-else></i>
                {{ yesterdayData.day_to_day_spent }} <i v-if="yesterdayData.day_spent_type != 0">%</i>
              </p>
            </el-col>
          </el-row>
          <el-divider class="never-divider"/>
          <span class="HelpText">日均消耗量：$ {{ $filters.thousands(yesterdayData.day_avg_spent) }}</span>
        </el-card>
      </el-col>
      <!-- 昨日数据表2 -->
      <el-col :span="6" style="min-width:290px;">
        <el-card shadow="never" class="neverCard">
          <div class="never-header">
            <span class="Text">CPM</span>
            <el-tooltip class="item" effect="light" content="" placement="top">
              <template #content>
                CPM：<br/>
                平均每一千人看到广告一次一共需要的广告成本<br/>
                日均点击量：<br/>
                广告账户一周内点击量平均值
              </template>
              <i class="el-icon-warning-outline"></i>
            </el-tooltip>
          </div>
          <p class="MainHead never-p">{{ yesterdayData.yesterday_cpm }}</p>
          <!-- CPM图表 -->
          <div id="CPM" class="yesterday-data-content"></div>
          <el-divider class="never-divider"/>
          <span class="HelpText">日均点击量：{{ $filters.thousands(yesterdayData.day_avg_click) }}</span>
        </el-card>
      </el-col>
      <!-- 昨日数据表3 -->
      <el-col :span="6" style="min-width:290px;">
        <el-card shadow="never" class="neverCard">
          <div class="never-header">
            <span class="Text">CTR</span>
            <el-tooltip class="item" effect="light" content="" placement="top">
              <template #content>
                CTR：<br/>
                广告的实际点击次数除以广告的展现量
              </template>
              <i class="el-icon-warning-outline"></i>
            </el-tooltip>
          </div>
          <p class="MainHead never-p">{{ yesterdayData.yesterday_ctr }}%</p>
          <el-progress
              :show-text="false"
              color="#2b82ff"
              :stroke-width="16"
              :percentage="yesterdayData.yesterday_ctr * 10"
              style="margin: 39px 0 10px;"
          >
            <span>{{ yesterdayData.yesterday_ctr }}%</span>
          </el-progress>
          <div class="progressRange">
            <span class="Text">0</span>
            <span class="Text">10%</span>
          </div>
          <el-divider class="never-divider"/>
          <el-row>
            <el-col :span="12">
              <p class="never-bp HelpText">
                <span>周同比</span>
                <i class="el-icon-caret-top" v-if="yesterdayData.week_ctr_type == 1"></i>
                <i class="el-icon-caret-bottom" v-else-if="yesterdayData.week_ctr_type == 2"></i>
                <i v-else></i>
                {{ yesterdayData.week_on_week_ctr }} <i v-show="yesterdayData.week_ctr_type != 0">%</i>
              </p>
            </el-col>
            <el-col :span="12">
              <p class="never-bp HelpText">
                <span>日环比</span>
                <i class="el-icon-caret-top" v-if="yesterdayData.day_ctr_type == 1"></i>
                <i class="el-icon-caret-bottom" v-else-if="yesterdayData.day_ctr_type == 2"></i>
                <i v-else></i>
                {{ yesterdayData.day_to_day_ctr }} <i v-show="yesterdayData.day_ctr_type != 0">%</i>
              </p>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <!-- 昨日数据表4 -->
      <el-col :span="6" style="min-width:290px;">
        <el-card shadow="never" class="neverCard">
          <div class="never-header">
            <span class="Text">ROAS</span>
            <el-tooltip class="item" effect="light" content="" placement="top">
              <template #content>
                ROAS：<br/>
                投资回报率，展现从广告中获得的的平均收益<br/>
              </template>
              <i class="el-icon-warning-outline"></i>
            </el-tooltip>
          </div>
          <p class="MainHead never-p">{{ yesterdayData.yesterday_roas }}</p>
          <!-- ROAS图表 -->
          <div id="ROAS" class="yesterday-data-content"></div>
          <el-divider class="never-divider"/>
          <span class="HelpText">
              <span>
                比平均水平
                <i v-if="yesterdayData.avg_roas_type == 1">高</i>
                <i v-else-if="yesterdayData.avg_roas_type == 2">低</i>
                <!-- <i v-else></i> -->
              </span>
              <i class="el-icon-caret-top" v-if="yesterdayData.avg_roas_type == 1"></i>
              <i class="el-icon-caret-bottom" v-else-if="yesterdayData.avg_roas_type == 2"></i>
            <!-- <i v-else></i> -->
              {{ yesterdayData.avg_roas }} <i v-show="yesterdayData.avg_roas_type != 0">%</i>
            </span>
        </el-card>
      </el-col>
    </el-row>
  </el-card>

  <el-card class="box-card" id="secondRow" v-loading="dailyLoading">
    <template #header>
      <div class="card-header">
        <span class="SubHead">广告账户趋势</span>
        <div>
          <el-row :gutter="10">
            <el-select
                v-model="dailyForm.spent_type"
                placeholder="请选择范围"
                size="small"
                @change="fetchDailySpent"
                style="width:90px;margin-right: 10px;"
            >
              <el-option label="按天" :value="1"></el-option>
              <el-option label="按周" :value="2"></el-option>
              <el-option label="按月" :value="3"></el-option>
            </el-select>
            <el-date-picker
                v-model="dailyForm.spent_time"
                type="daterange"
                size="small"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="X"
                unlink-panels
                :shortcuts="shortcuts"
                :disabled-date="disabledDate"
                @blur="fetchDailySpent"
            ></el-date-picker>
          </el-row>
        </div>
      </div>
    </template>
    <el-row class="trendData">
      <el-col :span="18">
        <!-- cost图表 -->
        <el-select v-model="dailyForm.type" @change="fetchDailySpent" size="small" style="margin-bottom: 16px">
          <el-option v-for="(label, key) in calcType()" :key="key" :label="label" :value="key"></el-option>
        </el-select>
        <div id="cost" style="width: 100%;height:350px;"></div>
      </el-col>
      <el-col :span="6">
        <div class="account-rank">
          <span class="SmallHead">广告消耗量排名</span>
          <el-table class="trendTable" :data="accountRank" style="width: 100%;" :show-header="false">
            <el-table-column prop="top" width="50">
              <template #default="{row}">
                <el-tag type="primary" size="small" effect="dark" v-if="row.top <= 3">
                  {{ row.top }}
                </el-tag>
                <el-tag v-else type="info" size="small" effect="light">
                  {{ row.top }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="account_id" min-width="150"/>
            <el-table-column prop="spent" min-width="150">
              <template #default="{row}"> $ {{ $filters.thousands(row.sum_spent) }}</template>
            </el-table-column>
            <template #empty>
              <img src="@/assets/empty.gif" alt="" style="width:200px;height:200px"/>
            </template>
          </el-table>
        </div>
      </el-col>
    </el-row>
  </el-card>

  <el-row :gutter="10" id="thirdRow">
    <!-- 账户成效分析 -->
    <el-col :span="12">
      <el-card class="box-card orderCard" style="min-height: 527px; margin-bottom: 0px" v-loading="roasLoading">
        <template #header>
          <div class="card-header">
            <span class="SubHead">账户成效分析</span>
            <el-row align="middle">
              <span class="SmallHead">数据归因：</span>
              <el-select
                  v-model="roasForm.roas_type"
                  placeholder=""
                  size="small"
                  style="width: 160px;"
                  @change="fetchRoasData()">
                <el-option label="点击后7天内" :value="1"></el-option>
                <el-option label="点击后1天内" :value="2"></el-option>
                <el-option label="浏览后1天内" :value="3"></el-option>
              </el-select>
              <el-button type="text" size="small" class="cutBtn LinkText"
                         @click="fetchRoasData(2)"
                         v-if="roasForm.type === 1">
                <i class="el-icon-sort"></i> 切换到ROAS
              </el-button>
              <el-button type="text" size="small" class="cutBtn LinkText" @click="fetchRoasData(1)" v-else>
                <i class="el-icon-sort"></i> 切换到购物数量
              </el-button>
            </el-row>
          </div>
        </template>
        <div class="roasList">
          <p class="Text cut-p">{{ roasForm.type == 1 ? '购物数量' : 'ROAS' }}</p>
          <p class="cut-p">
            <span class="MainHead" style="margin-right: 30px;">
              {{ $filters.thousands(roasForm.type == 1 ? roasData.sum_purchase : roasData.sum_roas) }}
            </span>
            <span class="HelpText" v-if="roasForm.type == 1">
              周同比 {{ $filters.thousands(roasData.week_on_week_purchase) }}<i
                v-if="roasData.week_purchase_type != 0">%</i>
              <i class="el-icon-top" v-if="roasData.week_purchase_type == 1"></i>
              <i class="el-icon-bottom" v-else-if="roasData.week_purchase_type == 2"></i>
              <i v-else></i>
            </span>
            <span class="HelpText" v-else>
              周同比 {{ $filters.thousands(roasData.week_on_week_roas) }}<i v-if="roasData.week_roas_type != 0">%</i>
              <i class="el-icon-top" v-if="roasData.week_roas_type == 1"></i>
              <i class="el-icon-bottom" v-else-if="roasData.week_roas_type == 2"></i>
              <i v-else></i>
            </span>
          </p>
          <div id="order" v-if="roasForm.type == 1" style="width: 100%;height:100px;"></div>
          <div id="roas1" v-else style="width: 100%;height:100px;"></div>
          <el-table
              :data="roasForm.type == 1 ? roasData.click_purchase : roasData.roas"
              style="width: 100%"
              :header-cell-style="{
              background: 'var(--el-table-row-hover-background-color)'
            }"
          >
            <el-table-column property="top" label="排名" width="100px"/>
            <el-table-column property="account_id" label="广告ID" width="160"/>
            <el-table-column v-if="roasForm.type == 1" label="购物数量" sortable>
              <template #default="{row}">
                {{ $filters.thousands(row.sum_purchase) }}
              </template>
            </el-table-column>
            <el-table-column v-else property="sum_roas" label="ROAS" sortable/>
            <el-table-column label="周涨幅">
              <template #default="{row}">
                {{ row.contrast }} <i v-show="row.type != 0">%</i>
                <i class="el-icon-top" v-if="row.type == 1"></i>
                <i class="el-icon-bottom" v-else-if="row.type == 2"></i>
                <i v-else></i>
              </template>
            </el-table-column>
            <template #empty>
              <img src="@/assets/empty.gif" alt="" style="width:100px;height:100px"/>
            </template>
          </el-table>
        </div>
      </el-card>
    </el-col>

    <!-- 广告投放地区数据 -->
    <el-col :span="12">
      <el-card class="box-card areaCard" style="min-height: 500px; margin-bottom: 0px">
        <template #header>
          <div class="card-header">
            <span class="SubHead">广告投放地区数据</span>
            <el-date-picker
                v-model="countryForm.spent_time"
                type="daterange"
                size="small"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="X"
                unlink-panels
                :shortcuts="shortcuts"
                :disabled-date="disabledDate"
                @calendar-change="fetchCountryData"
                @blur="fetchCountryData"
            ></el-date-picker>
          </div>
        </template>
        <!-- area表单 -->
        <el-row v-if="countryData.length">
          <el-col :span="17">
            <div id="area" style="width: 100%;height:393px;"></div>
          </el-col>
          <el-col :span="7" style="display: flex;flex-direction: column;justify-content: center;">
            <ul class="pieData">
              <li class="Text" v-for="(item, index) in countryData" :key="index">
                <span>{{ item.percent }}%</span>
                <span>${{ $filters.thousands(item.amount) }}</span>
              </li>
            </ul>
          </el-col>
        </el-row>
        <el-empty :image-size="300" v-else>
          <template #image>
            <img src="@/assets/empty.gif"/>
          </template>
        </el-empty>
      </el-card>
    </el-col>
  </el-row>
</template>

<script setup>
import {ref, onMounted, nextTick, onBeforeUnmount} from "vue";
import {getFBYesterdayData, getFBDailyData, getFBRoasData, getFBCountryData} from '@/request/api/account-panel'
import * as echarts from 'echarts';
import AccountLabelFilter from "@/components/base/AccountLabelFilter";
import Driver from "driver.js";
import {setIntro} from "@/request/api/common";
import base from "@/base/filters/base";

//页面选择标签，影响整个页面
let labelIds = ref(0)

let pageCharts = ref({
  cpm: null,
  roas: null,
  cost: null,
  order: null,
  roas1: null,
  area: null,
})

let pageChartsFirstRendered = ref([])
let showGuideIntervalId = ref(0)

//昨日数据初始化
let getYesterdayData = (data = {}) => {
  return Object.assign({
    'intro': 0,
    'yesterday_spent': 0,
    'week_on_week_spent': 'string',
    'week_spent_type': 0,
    'day_to_day_spent': 0,
    'day_spent_type': 0,
    'day_avg_spent': 0,
    'yesterday_cpm': 0,
    'week_cpm': {},
    'day_avg_click': 0,
    'yesterday_ctr': 0,
    'week_on_week_ctr': 'string',
    'week_ctr_type': 0,
    'day_to_day_ctr': 0,
    'day_ctr_type': 0,
    'yesterday_roas': 0,
    'week_roas': {},
    'avg_roas': 0,
    'avg_roas_type': 0
  }, data)
}
let yesterdayData = ref(getYesterdayData())
//昨日数据loading
let yesterdayLoading = ref(false)

//新手指引，依赖于昨日数据获取接口
let driverPanel = ref(null)

//账户趋势搜索表单
let dailyForm = ref({
  spent_time: base.getDefaultDuration(30),
  spent_type: 1,
  type: 'spent'
})

let dailyLoading = ref(false)
//账户排名数据，依赖于账户趋势数据获取接口
let accountRank = ref([])

//成效分析搜索表单
let roasForm = ref({
  roas_type: 1,
  type: 1
})

//成效分析数据初始化
let getRoasData = (data = {}) => {
  return Object.assign({
    week_on_week_purchase: 0,
    week_purchase_type: 0,
    sum_purchase: 0,
    week_purchase: [],
    click_purchase: [],
    week_on_week_roas: 0,
    week_roas_type: 0,
    sum_roas: 0,
    roas: [],
  }, data)
}
let roasData = ref(getRoasData())
//成效分析数据loading
let roasLoading = ref(false)

//投放地区数据
let countryData = ref([])
//投放地区数据loading
let countryLoading = ref(false)
//投放地区数据搜索表单
let countryForm = ref({spent_time: base.getDefaultDuration(7)})

onMounted(() => {
  fetchData()
})

//监听窗口变化
window.addEventListener('resize', () => {
  for (let key in pageCharts.value) {
    if (pageCharts.value[key] && pageCharts.value[key].resize) {
      pageCharts.value[key].resize()
    }
  }
})

onBeforeUnmount(() => {
  if (driverPanel.value && driverPanel.value.reset) {
    driverPanel.value.reset()
  }

  if (showGuideIntervalId.value) clearInterval(showGuideIntervalId.value)
})

// 新手引导
let panelGuide = () => {
  nextTick(() => {
    driverPanel.value = new Driver({
      allowClose: false,
      opacity: 0.5,
      nextBtnText: '下一步',
      closeBtnText: '跳过',
      doneBtnText: '完成',
      onReset: () => {
        document.querySelector('.el-main').style.overflowY = 'auto'
        document.querySelector('.el-main').scrollTop = 0
        setIntro('dashboard')
      },
    })
    if (driverPanel.value.defineSteps) {
      driverPanel.value.defineSteps([
        {
          element: '#firstRow',
          popover: {
            className: 'first-step-popover-class',
            title: '新手指引(1/3)',
            description:
                '这里展示的是您所有广告数据的汇总，您可以在这里快速的查看昨日的广告运行情况，点击叹号可查看每个指标的定义及计算公式。',
            position: 'bottom-right'
          }
        },
        {
          element: '#secondRow',
          popover: {
            title: '新手指引(2/3)',
            description: '在这里查看您的广告账户消耗的趋势折线图，以及广告账户的消耗排名。',
            position: 'top-right'
          }
        },
        {
          element: '#thirdRow',
          popover: {
            title: '新手指引(3/3)',
            description: '在这里查看您的广告账户的成效分析。',
            position: 'top-right',
            closeBtnText: '',
            doneBtnText: '完成'
          }
        }
      ])
    }
    if (driverPanel.value.start) {
      document.querySelector('.el-main').scrollTop = 0
      driverPanel.value.start()
      document.querySelector('.el-main').style.overflowY = 'hidden'
    }
  })
}

//标签数据更新
let onLabelSelectedChange = event => {
  labelIds.value = event.join(',')
  fetchData()
}

let fetchData = () => {
  fetchYesterdayData()
  fetchDailySpent()
  fetchRoasData()
  fetchCountryData()
}

let fetchYesterdayData = async () => {
  yesterdayLoading.value = true
  let res = await getFBYesterdayData({label: labelIds.value}).finally(() => yesterdayLoading.value = false)

  yesterdayData.value = getYesterdayData(res && res.data ? res.data : {})
  let yAxis = {axisLine: {show: false}, axisTick: {show: false}, axisLabel: {show: false}, splitLine: {show: false}}
  pageCharts.value.cpm = renderChart('CPM', res.data && res.data.week_cpm ? res.data.week_cpm : {}, 'line', {}, yAxis, ['#7749fc'], {
    areaStyle: {opacity: 0.4},
    smooth: true
  })
  pageCharts.value.roas = renderChart('ROAS', res.data && res.data.week_roas ? res.data.week_roas : {}, 'bar', {}, yAxis)

  if (yesterdayData.value.intro === 1 && !showGuideIntervalId.value) {
    nextTick(() => {
      showGuideIntervalId.value = setInterval(() => {
        let renderFinished = Object.keys(pageCharts.value).filter(item => {
          return pageChartsFirstRendered.value.indexOf(item) > -1
        })
        if (renderFinished.length >= 5) {
          clearInterval(showGuideIntervalId.value)
          panelGuide()
          console.log('渲染完成，展示新手指引')
        }
      }, 500)
      setTimeout(() => {
        clearInterval(showGuideIntervalId.value)
        console.log('20s检查超时关闭')
      }, 20000)
    })
  }
}

let fetchDailySpent = async () => {
  dailyLoading.value = true
  let res = await getFBDailyData({
    spent_time: dailyForm.value.spent_time,
    label: labelIds.value,
    spent_type: dailyForm.value.spent_type,
    type: dailyForm.value.type
  }).finally(() => dailyLoading.value = false)

  accountRank.value = res.data.account_spent ? res.data.account_spent : []

  pageCharts.value.cost = renderChart(
      'cost',
      res.data.spent,
      'line',
      {show: true}, {},
      ['#2b82ff', '#00f1a2', '#ffb900', '#F56C6C', '#7749fc', '#73C0DE'],
      {}, {
        top: '50px',
        left: '70px',
        right: '40px',
        bottom: '40px'
      }, {
        text: calcType(dailyForm.value.type),
        textStyle: {
          color: '#595959',
          fontSize: 14,
          fontWeight: 600
        },
        left: 10
      }, {type: 'scroll', top: 'top', right: '40px'})
}

let fetchRoasData = async (type = undefined) => {
  roasLoading.value = true
  if ([1, 2].indexOf(type) >= 0) roasForm.value.type = type
  let res = await getFBRoasData({
    roas_type: roasForm.value.roas_type,
    type: roasForm.value.type,
    label: labelIds.value,
  }).finally(() => roasLoading.value = false)

  roasData.value = getRoasData(res && res.data ? res.data : {})

  nextTick(() => {
    let chart = renderChart(
        roasForm.value.type == 1 ? 'order' : 'roas1',
        res.data ? (roasForm.value.type == 1 ? res.data.week_purchase : res.data.week_roas) : {},
        'line',
        {show: false, boundaryGap: false,},
        {show: false, boundaryGap: false,},
        [roasForm.value.type == 1 ? '#409EFF' : '#67C23A'],
        {areaStyle: {opacity: 0.4}, smooth: true,},
        {
          top: '0px', left: '5px', right: '5px', bottom: '3px'
        }
    )
    if (roasForm.value.type == 1) {
      pageCharts.value.order = chart
      pageCharts.value.roas1 = null
    } else {
      pageCharts.value.order = chart
      pageCharts.value.roas1 = null
    }
  })

}

let fetchCountryData = async () => {
  countryLoading.value = true
  let res = await getFBCountryData({
    label: labelIds.value,
    spent_time: countryForm.value.spent_time
  }).finally(() => countryLoading.value = false)
  countryData.value = res.data && res.data.pieData ? res.data.pieData : []

  nextTick(() => {
    pageCharts.value.area = renderChart('area', res.data && res.data.area_series ? res.data.area_series : {},
        'pie', {}, {},
        ['#2b82ff', '#00f1a2', '#ffb900', '#F56C6C', '#7749fc', '#73C0DE'],
        {
          radius: ['40%', '70%'],
          center: ['40%', '50%'],
          avoidLabelOverlap: false,
          itemStyle: {borderRadius: 0, borderColor: '#fff', borderWidth: 4},
          label: {show: false, position: 'center'},
          emphasis: {label: {show: true, fontSize: '30', fontWeight: 'bold'}},
          labelLine: {show: false},
        }, {}, {},
        {orient: 'vertical', top: 'center', left: '75%'},
        {trigger: 'item'})
  })
}

let calcType = (key = '') => {
  let map = {
    spent: '账户消耗',
    clicks: '点击数',
    reach: '展示数',
    impressions: '转化数',
    cpc: 'CPC',
    cpm: 'CPM',
    cpa: 'CPA',
    ctr: 'CTR',
    roas: 'ROAS'
  }
  return key ? (map[key] ? map[key] : '') : map
}

let renderChart = (elementId, data, type = 'line', xAxis = {}, yAxis = {}, colors = [], seriesStyle = {}, grid = {}, title = {}, legend = {}, tooltip = {}) => {
  if (pageChartsFirstRendered.value.indexOf(elementId.toLowerCase()) > -1) pageChartsFirstRendered.value.splice(pageChartsFirstRendered.value.indexOf(elementId.toLowerCase()), 1)

  if (!document.getElementById(elementId)) {
    if (pageChartsFirstRendered.value.indexOf(elementId.toLowerCase()) === -1) pageChartsFirstRendered.value.push(elementId.toLowerCase())
    return null
  }
  document.getElementById(elementId).removeAttribute("_echarts_instance_")
  let chart = echarts.init(document.getElementById(elementId))

  let series = []
  if (data && data.series && data.series.length > 0) {
    data.series.forEach(item => {
      series.push(Object.assign({
        name: item.name ? item.name : '',
        data: item.data ? item.data : [],
        type: type,
      }, seriesStyle))
    })
  }

  chart.setOption({
    title: title,
    color: colors.length ? colors : ['#2b82ff'],
    tooltip: Object.assign({
      trigger: 'axis'
    }, tooltip),
    legend: Object.assign(data && data.legend ? {data: data.legend} : {}, legend),
    xAxis: Object.assign({
      type: 'category',
      boundaryGap: false,
      data: [],
      show: false
    }, data && data.xAxis ? data.xAxis : {}, xAxis),
    yAxis: Object.assign({
      type: 'value',
    }, yAxis),
    series: series,
    grid: Object.assign({
      top: '5px',
      bottom: '4px'
    }, grid)
  })

  chart.on('finished', () => {
    if (pageChartsFirstRendered.value.indexOf(elementId.toLowerCase()) === -1) pageChartsFirstRendered.value.push(elementId.toLowerCase())
  })

  return chart
}

let shortcuts = [
  {
    text: '昨天',
    value: () => {
      let start = new Date()
      let end = new Date()
      start.setTime(start.getTime() - 8.64e7)
      end.setTime(end.getTime() - 8.64e7)
      return [start, end]
    }
  },
  {
    text: '近7天',
    value: () => {
      let start = new Date()
      let end = new Date()
      start.setTime(start.getTime() - 8.64e7 * 7)
      end.setTime(end.getTime() - 8.64e7)
      return [start, end]
    }
  },
  {
    text: '近30天',
    value: () => {
      let start = new Date()
      let end = new Date()
      start.setTime(start.getTime() - 8.64e7 * 30)
      end.setTime(end.getTime() - 8.64e7)
      return [start, end]
    }
  },
  {
    text: '近90天',
    value: () => {
      let start = new Date()
      let end = new Date()
      start.setTime(start.getTime() - 8.64e7 * 90)
      end.setTime(end.getTime() - 8.64e7)
      return [start, end]
    }
  }
]

// 日期选择器禁止选择的条件
let disabledDate = (time) => {
  // 8.64e7 等于 3600 * 1000 * 24
  if (dailyForm.value.spent_time && dailyForm.value.spent_time[0]) {
    return (
        time.getTime() > Date.now() - 8.64e7 ||
        time.getTime() < 1640966400 * 1000 ||
        time.getTime() > dailyForm.value.spent_time[0] * 1000 + 180 * 8.64e7 ||
        time.getTime() < dailyForm.value.spent_time[0] * 1000 - 180 * 8.64e7
    )
  } else {
    return time.getTime() < 1640966400 * 1000 || time.getTime() > Date.now() - 8.64e7
  }
}
</script>

<style lang="less" scoped>
a {
  text-decoration: none;
}

.box-card {
  margin-bottom: 20px;
}

.card-header,
.never-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.neverCard {
  height: 230px;
  overflow: inherit;

  :deep(.el-card__header) {
    border-bottom: none;
  }
}

i.el-icon-warning-outline {
  font-size: 18px;
  color: #909399;
  cursor: pointer;
}

p.never-p,
p.cut-p {
  margin: 10px 0;
}

p.never-p {
  margin-top: 12px;
}

p.never-bp {
  margin: 0;
}

.never-divider {
  margin: 10px 0;
}

.trendDetail {
  margin-left: 10px;
}

.trendData,
.oderList,
.roasList {
  .el-table {
    :deep(tr) {
      height: auto;
    }

    :deep(.el-table__cell) {
      padding: 8px 0;
    }

    .el-tag {
      border-radius: 50%;
    }
  }
}

.cutBtn {
  width: 120px;
  margin-left: 10px;
  padding: 0 !important;

  .el-icon-sort {
    font-size: 16px;
  }
}

.el-icon-top,
.el-icon-caret-top {
  color: #00f1a2;
}

.el-icon-bottom,
.el-icon-caret-bottom {
  color: #f56c6c;
}

p.cut-p {
  display: flex;
  align-items: center;
}

.pieData {
  width: 100%;
  margin-top: 10px;

  li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    border-left: 1px solid #d9d9d9;
    padding-left: 8%;

    span {
      width: 50%;
    }
  }
}

.progressRange {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.reportDetail {
  font-size: 14px;
  color: #2b82ff;
}

.orderCard,
.areaCard {
  :deep(.el-card__body) {
    height: 420px;
  }
}

.facebook-ad-panel .yesterday-data-content {
  width: 100%;
  height: 75px;
  align-content: center;
}

.facebook-ad-panel .account-rank {
  position: relative;
  height: calc(100% - 40px);
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #595959;
  line-height: 22px;
  overflow: hidden;
  overflow-x: auto;
  overflow-y: auto;
  padding-top: 32px;
}

.facebook-ad-panel .account-rank .SmallHead {
  position: absolute;
  top: 0;
  left: 0;
}

.facebook-ad-panel .account-rank .item {
  padding: 8px 0;
  min-width: 280px;
  display: inline-block;
}

.facebook-ad-panel .account-rank .el-tag {
  border-radius: 50%;
}

.facebook-ad-panel .account-rank .account-id {
  min-width: 150px;
  display: inline-block;
  padding-left: 10px;
  text-align: left;
}

.facebook-ad-panel .account-rank .spent {
  min-width: 100px;
  padding-left: 10px;
  text-align: left;
}
</style>

<style>
.facebook-ad-panel .el-tabs__header {
  margin-bottom: 0 !important;
}

.facebook-ad-panel .label-card {
  border-radius: 0;
  margin-bottom: 15px;
  border: 0;
}
</style>
